<template>
  <section>
    <hero-global title="Planes de actuación" subtitle="Planes de actuación anuales" first-width="78" last-width="22" img="/img/annual-action-plans/annual-action-plans_1.svg" />
  </section>
  <div class="container p-1-rem">
    <section class="container">
      <div :class="[
                          showMoreAnnualPlans
                            ? 'boton-features-div'
                            : 'boton-features-div inactive'
                        ]">
        <template v-if="showMoreAnnualPlans">
          <p class="title -intro features contraer">Contraer todo</p>
        </template>
        <template v-else>
          <p class="title -intro features contraer">Expandir todo</p>
        </template>
        <div class="boton-azul" @click="toggleShowMoreMemoriesAnual">
          <div class="boton-circulo"></div>
        </div>
      </div>
      <ul class="list-item-dates">

        <template v-for="(year, key) in Object.values(arrayCompletYears)" :key="key">
          <li class="item-list" v-if="getDataPlan(year).items">
            <span class="date">{{year}}</span>
            <div class="content-date">
              <p class="title" @click="toggleFeatureItem(year)">Plan de actuación {{year}}</p>
              <div v-if="getDataPlan(year).items" :class="[showMoreAnnualPlans ? 'feature list-download' : 'feature list-download inactive']" :data-year="year">
                <a v-for="(file, key) in getDataPlan(year).items" :key="key" :href="file.file.url" target="_blank" title="Descargar archivo" :download="file.name" :data-type="file.file.type">Descargar en {{file.file.type.replace(file.file.type.substr(0, (file.file.type.indexOf('/')+1)), '')}}</a>
              </div>
            </div>
          </li>
        </template>
        <div class="column-center">
          <button class="btn-white" @click="getMoreYear" v-if="showMore" title="Ver más" alt="Ver más"> Ver más</button>
        </div>
      </ul>
    </section>
      <carrousel2 :dataContent="$tm('translations.carrouselData.cermi')"/>

      <other-links-interest class="links-interest-box container" />
    <br />
    <br />
  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import { contentStore } from '@/stores/contents'
  export default {
    name: "AnnualActionPlans",
    components: {
      OtherLinksInterest,
      Carrousel2,
      HeroGlobal,
    },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      this.store.loadPlans({ arrayYears: this.arrayYears, maxYear: this.maxYear })
      this.arrayCompletYears = this.arrayYears
    },
    data() {
      return {
        showMoreAnnualPlans: true,
        showMore: true,
        maxYear: new Date().getFullYear(),
        arrayCompletYears: [],
        minYear: 2002
      }
    },
    methods: {
      toggleAnualPlans: function () {
        this.showAllAnualPlans = !this.showAllAnualPlans
      },
      toggleShowMoreMemoriesAnual: function () {
        this.showMoreAnnualPlans = !this.showMoreAnnualPlans
      },
      toggleFeatureItem: function (val) {
        if (val != null) {
          document.querySelector('.feature[data-year="' + val + '"]').classList.toggle("inactive")
        }
      },
      scrollSection: function (params) {
        if (params) {
          const offsetTop = document.getElementById(params).offsetTop - 150;
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: offsetTop,
            });
          }, 200);
        }
      },
      range: function range(start, stop = undefined, step = 1) {
        const startArray = stop === undefined ? 0 : start;
        const stopArray = stop === undefined ? start : stop;
        return Array.from({ length: (stopArray - startArray) / step + 1 }, (_, i) => startArray + (i * step)).reverse();
      },
      getMoreYear: function name(maxyear) {
        this.maxYear = this.maxYear - 12
        this.arrayCompletYears = this.arrayCompletYears.concat(this.arrayYears)
        if (this.minYear <= 2002) {
          this.showMore = false
        }
        this.store.loadPlans({ arrayYears: this.arrayYears, maxYear: this.maxYear })
      },
      getDataPlan: function (year) {
        if (this.store.plans[year] && this.store.plans[year].items.length > 0) {
          return this.store.plans[year]
        }
        return "Lo sentimos no hay resultados"

      }

    },
    computed: {
      arrayYears() {
        return this.range(this.minYear, this.maxYear)
      },
/*       minYear() {
        if (this.minYear <= 2002) {
          return 2002
        } else {
          
          return (this.maxYear - 10);

        }
      }, */
    }
  }
</script>

<style scoped>

</style>